<template>
  <footer class="footer-secondary" :class="{ margin: isMarginBottom }">
    <div class="content">
      <div class="menu">
        <NuxtLink v-for="(l, i) in menu" :key="l.slug" class="menu-link" :to="l.slug">
          {{ l.name }}
          <Icon v-if="i !== menu.length - 1" name="dot" class="dot" height="4px" width="4px" />
        </NuxtLink>
        <span class="date-item">{{ copyright }}</span>
      </div>
      <span class="date-item-mobile">{{ copyright }}</span>
    </div>
    <Icon class="lmdv-logo" height="49px" width="100px" name="lmdv-logo" />
  </footer>
</template>

<script lang="ts" setup>
defineProps<{
  menu: { name: string; slug: string }[];
}>();

const copyright = `© ${new Date().getFullYear()} Les Maisons du Voyage`;

const isMarginBottom =
  useRoute().name === 'geographiczoneslug-countryslug-travelstyleslug-productid';
</script>

<style scoped lang="scss">
@use '$/colors.scss';
@use '$/mouse-behavior.scss';

.footer-secondary {
  position: relative;

  flex-wrap: wrap;

  // justify-content: flex-start; // old:$

  justify-content: center;

  width: 100%;
  padding: 0 16px;

  background-color: colors.$green;

  &.margin {
    margin-bottom: 25px;
  }

  .content {
    flex-wrap: wrap;
    width: 100%;
    max-width: 1000px;
    padding: 20px 0;

    .menu {
      flex-wrap: wrap;
      gap: 50px; // old : 16px
      justify-content: center;

      // old: justify-content: space-between;
      width: 100%;
      padding-left: 11%;

      .menu-link {
        display: flex;
        gap: 5px;
        align-items: center;

        font-size: 13px;
        color: white;
        text-align: center;

        .dot {
          display: none;
        }
      }
    }

    .date-item {
      font-size: 13px;
      color: white;
      text-align: center;
    }

    .date-item-mobile {
      display: none;
    }
  }

  .lmdv-logo {
    position: absolute;
    top: 5px; // old top: 3px;
    right: 0;
    bottom: 5px;
  }
}

@media (width <= 768px) {
  .footer-secondary {
    .lmdv-logo {
      display: none;
    }

    .content {
      flex-direction: column;
      gap: 16px;
      align-items: center;

      .menu {
        gap: 5px;
        justify-content: center;
        padding-left: 0;

        .menu-link {
          .dot {
            display: block;
            margin-bottom: 0; // old: 5px
          }
        }
      }

      .date-item-mobile {
        display: flex;

        font-size: 13px;
        color: white;
        text-align: center;
        text-transform: capitalize;
      }
    }

    .date-item {
      display: none;
    }
  }
}
</style>
